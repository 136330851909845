<template>
  <div ref="panel" v-editable class="relative mx-auto w-full max-w-[466px]">
    <button
      :class="{
        'rounded-xl bg-opacity-90': !isOpen,
        'rounded-b-0 rounded-t-xl': isOpen,
      }"
      class="ease-out-expo flex h-16 w-full items-center justify-between bg-white px-5 text-left text-gray-900 transition-all duration-300 lg:h-16"
      @click="toggleIsOpen()"
    >
      {{ blok.label }}

      <div
        :class="{
          'rotate-180': isOpen,
          'rotate-0': !isOpen,
        }"
        class="ease-out-expo transition-transform duration-300"
      >
        <UiIcon icon="chevron-down" size="md" class="text-gray-700" />
      </div>
    </button>

    <div
      :class="{
        'pointer-events-auto visible opacity-100': isOpen,
        'pointer-events-none invisible opacity-0': !isOpen,
      }"
      class="ease-out-expo absolute left-0 top-16 max-h-[18.75rem] w-full overflow-auto rounded-b-lg bg-white text-gray-900 shadow-xl transition-all duration-300 lg:top-14"
    >
      <ul class="pb-2">
        <li
          v-for="(story, index) in orderedStories"
          :key="story.id"
          :class="{
            'bg-gray-200 pt-[2px]':
              story.content.component === 'ContentTypeState' && index !== 0,
          }"
        >
          <div
            :class="{
              'bg-white pt-3': story.content.component === 'ContentTypeState',
            }"
          >
            <NuxtLink
              :to="formatLink(story.full_slug)"
              :class="{
                'hover:border-b-green border-b border-b-gray-200 py-3':
                  story.content.component === 'ContentTypeState',
                'hover:border-b-green border-b border-gray-200 py-3 text-sm':
                  story.content.component === 'ContentTypeCommunity',
              }"
              class="storys-center active:text-primary-500 ease-out-expo flex gap-2 bg-white px-5 text-gray-700 transition-colors duration-300 hover:text-gray-900 active:bg-gray-200"
            >
              <span
                class="ease-out-expo font-bold transition-colors duration-700"
              >
                {{ getMenuItemLabel(story) }}
              </span>
              <span v-if="story.content.component === 'ContentTypeCommunity'">
                {{ story.name }}
              </span>
            </NuxtLink>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISbStoryData } from 'storyblok'
import { useToggle, onClickOutside } from '@vueuse/core'
import { formatLink } from '@/utils/link-utils'
import type { BlockHeroSearchStoryblok } from '@/types/storyblok'

const props = defineProps<{ blok: BlockHeroSearchStoryblok }>()
const [isOpen, toggleIsOpen] = useToggle()
const panel = ref<HTMLElement | null>(null)
const states = await fetchAllStates()
const communities = await fetchAllCommunities()

const orderedStories = computed(() => {
  const statesData = states.value?.data.stories || []
  const communitiesData = communities.value?.data.stories || []
  const menuItems = statesData.flatMap((state) => {
    return [
      state,
      ...communitiesData.filter((community) =>
        community.full_slug.startsWith(state.full_slug),
      ),
    ]
  })

  return props.blok.state
    ? menuItems.filter((story) =>
        story.full_slug.startsWith(`communities/${props.blok.state}`),
      )
    : menuItems
})

onClickOutside(panel, () => {
  if (isOpen.value) {
    toggleIsOpen()
  }
})

function getMenuItemLabel(story: ISbStoryData): string | undefined {
  if (story.content.component === 'ContentTypeCommunity') {
    return (
      story.content.suburb || story.content.displaySuiteLocation?.[0]?.suburb
    )
  }

  return story.name
}
</script>
